.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.sliding-container {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}

.sliding-wrapper {
  display: flex;
  width: 200%; /* This makes enough room for two sets of images */
  animation: slide 20s linear infinite; /* Adjust the duration as needed     */
}

.sliding-wrapper img {
  flex: none;
  max-height: 12rem;
  height: auto;
  display: inline-block;
}
